import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Importe useSelector
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import { debug, formatPrice, download, file } from './../../utils';
import { useTranslation } from 'react-i18next';
import ProdCodeStock from './ProdCodeStock';
import Modal from 'react-bootstrap/Modal';
import API from '../../api'
import './../../css/modals.css';
import './product-modal.css';
import InputQuantity from './../helpers/InputQuantity/InputQuantity';
import 'sweetalert2/dist/sweetalert2.min.css';
import { IoCaretBackSharp } from "react-icons/io5";
import { CiViewList } from "react-icons/ci";
import ProductRel from './ProductRel';
import ProductFeatures from './ProductFeatures';
import { AddCartItem } from '../../redux/cartSlice';
import Picture from '../helpers/Picture/Picture';
const ProductModal = ({ product, onClose, canOrderProduct = true }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [orderItem, setOrderItem] = useState({});
  const [itemAdded, setItemAdded] = useState(false);
  const [activeTab, setActiveTab] = useState('related');
  const [modalProduct, setModalProduct] = useState(false);
  const [previousProducts, setPreviousProducts] = useState([]);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if ([40, 108, 54, 38, 107, 187].indexOf(e.keyCode) > -1) {
        const step = ([40, 108, 54].indexOf(e.keyCode) > -1) ? -1 : +1;
        updateQuantity(step)
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  const updateQuantity = (step) => {
    setOrderItem((prevOrderItem) => {
      return { ...prevOrderItem, quantity: Math.max(1, prevOrderItem.quantity + step) };
    });
  }
  useEffect(() => {
    setOrderItem({ prodId: modalProduct.prodId, quantity: 1 });
  }, [modalProduct]);
  useEffect(() => {
    API.get('/product/' + product.prodId).then(reponse => {
      setModalProduct({ ...reponse.data, canBeOrdered: (canOrderProduct && reponse.data.prodPrix !== '0') });
    })
    // setModalProduct({ ...product, canBeOrdered: canOrderProduct });
    setItemAdded(false);
  }, [product])
  const handleOnHide = () => {
    onClose();
  }
  const handleQuantityUpdate = (quantity) => {
    setOrderItem({ ...orderItem, quantity });
  }
  const handleAddItem = async () => {
    console.log(orderItem)
    if (modalProduct.canBeOrdered == true) {
      try {
        dispatch(AddCartItem(orderItem)).then(() => {
          setItemAdded(true);
        }).catch((error) => {
          alert('Le produit ne peut pas être ajouté au panier');
        });
      } catch (error) {
        console.error('Erreur lors de la mise à jour des éléments du panier:', error);
      }
    }
  }
  const delivryInformationRequest = () => {
    Swal.fire({
      html: t('Delivry information request text'),
      showCloseButton: true,
      showCancelButton: false,
      confirmButtonText: t('Delivry information request button'),
      cancelButtonText: "Fermer",
      showLoaderOnConfirm: true,
      showConfirmButton: true,
      customClass: {
        popup: 'pb-5',
        htmlContainer: 'pt-5',
        confirmButton: 'btn-sm btn-green',
      },
      preConfirm: async () => {
        Swal.disableButtons();
        const response = await API.post('/product/delivryInformationRequest', { prodId: modalProduct.prodId });
        Swal.update({ showConfirmButton: false });
        Swal.update({
          html: t('Request sent'),
          showCancelButton: true,
          showConfirmButton: false,
          customClass: {
            popup: 'pb-3',
            htmlContainer: 'pt-5',
            confirmButton: 'hidden',
            cancelButton: 'btn-sm'
          },
        });
        return false;
      }
    });
  }
  const handleOnRelSelected = async (product) => {
    setPreviousProducts([...previousProducts, modalProduct]);
    try {
      const response = await API.get(`/product/${product.prodId}`);
      setItemAdded(false);
      setModalProduct({ ...response.data, canBeOrdered: true });
    } catch (e) {
    }
  }
  const backToPreviousProduct = () => {
    if (previousProducts.length > 0) {
      const updatedPreviousProducts = [...previousProducts];
      const lastPreviousProduct = updatedPreviousProducts[updatedPreviousProducts.length - 1]; // Obtient le dernier produit précédent
      updatedPreviousProducts.pop();
      setPreviousProducts(updatedPreviousProducts);
      setModalProduct(lastPreviousProduct);
    }
  }
  return (
    <Modal show={true} onHide={handleOnHide} className="product-modal" keyboard={true} >
      <Modal.Header closeButton>
        <div className='row flex-grow-1' >
          <div className='col-sm-4'>
            <Modal.Title>{t('Ref')} {modalProduct.prodRef}</Modal.Title>
          </div>
          <div className='col-sm-8 text-end pe-4'>
            {modalProduct.prodDatasheet != '' &&
              <span>
                {/* 2025-04-02
                <a href={e => download(`/product/datasheet/${modalProduct.prodId}`, true)} className='btn btn-light btn-datasheet'>
                  <CiViewList size="30" />
                  Fiche technique
                </a>
                */}
                <a href={`https://amd-jaeger.fr${modalProduct.prodDatasheet}`} className='btn btn-light btn-datasheet' target='_blank'>
                  <CiViewList size="30" />
                  Fiche technique
                </a>
              </span>
            }
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className='row'>
          <div className='col-sm-6'>
            <div className="mb-1 top-block p-3">
              {modalProduct.prodCatalogue == 'Kit' ?
                <div className='mb-4'>
                  <div className="d-flex flex-wrap flex-column">
                    {modalProduct.prodPicture != "" &&
                      <div className="w-100 flex-grow-0 me-2 mb-2 text-center">
                        <span className=" border rounded" >
                          <Picture src={modalProduct.prodPicture} style={{ maxWidth: '100%', maxHeight: '180px' }} />
                        </span>
                      </div>
                    }
                    <div className="flex-grow-1">
                      <strong className='d-block'>
                        {modalProduct.prodTitre}
                      </strong>
                    </div>
                  </div>
                  {modalProduct.prodMemo.trim() != '' && <small>
                    {modalProduct.prodMemo}</small>}
                </div>
                :
                <h4 className='mb-4'>
                  {modalProduct.prodMarque}<br /><small>{modalProduct.prodModele}</small>
                </h4>
              }
              <div className='row text-center mb-3'>
                <div className='col-4'>
                  {modalProduct.prodAnnee}
                </div>
                <div className='col-4 '>
                  <strong>
                    {modalProduct.prodPrix !== '0' ? formatPrice(modalProduct.prodPrix) : '---'}  € HT
                  </strong>
                </div>
                <div className='col-4'>
                  <ProdCodeStock codeStock={modalProduct.prodCodeStock} label={true} />
                </div>
              </div>
              {modalProduct.prodCodeStock == 0 &&
                <div className='mb-3'>
                  <button className='btn btn-light btn-sm' onClick={delivryInformationRequest}>Plus d'info sur le délai de livraison</button>
                </div>
              }
              {previousProducts.length > 0 && <div>
                <a className='previous-product' onClick={backToPreviousProduct}>
                  <IoCaretBackSharp className="me-1" />Retour au produit précédent
                </a>
              </div>
              }
            </div>
            {modalProduct.canBeOrdered == true && user.orderEnabled === '1' &&
              <div className="form-group mt-4 mb-4 p-3 order-block" >
                {itemAdded === false && <>
                  <h4>Commander</h4>
                  <div className='pt-2'>
                    {t('Quantity')} :
                    <InputQuantity quantity={orderItem.quantity} onUpdate={handleQuantityUpdate} disabled={false} size="lg" />
                    <div className='pt-3'>
                      <button className="btn btn-green" onClick={handleAddItem}>
                        Ajouter au panier
                      </button>
                    </div>
                  </div>
                </>}
                {itemAdded === true && <>
                  <h4>Produit ajouté au panier</h4>
                  <div className='row pt-3 product-added'>
                    <div className='col-6'>
                      <button className="btn btn-green" onClick={onClose}>Fermer</button>
                    </div>
                    <div className='col-6'>
                      <Link to="/cart" className="btn btn-green">Afficher le panier</Link>
                    </div>
                  </div>
                </>}
              </div>
            }
            <div className="row mb-3">
              <div className='col-sm-12'>
              </div>
            </div>
            {debug(modalProduct)}
          </div>
          <div className='col-sm-6'>
            <div className='tabs'>
              <a onClick={e => setActiveTab('related')} className={activeTab == 'related' ? 'active' : ''} >{t('Related products')}</a>
              <a onClick={e => setActiveTab('features')} className={activeTab == 'features' ? 'active' : ''}>{t('Features')}</a>
            </div>
            <div className=' right-side pt-3'>
              {activeTab == 'related' && <ProductRel parentId={modalProduct.prodId} onRelSelected={handleOnRelSelected} />}
              {activeTab == 'features' && <ProductFeatures prodId={modalProduct.prodId} />}
            </div>
          </div>
        </div>
        {false && <pre>{JSON.stringify(orderItem, null, 2)}</pre>}
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  )
}
export default ProductModal;
/*
*/
