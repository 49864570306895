import React, { useEffect, useState, useRef } from 'react';
import API from './../../api';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { debug } from './../../utils';
import InputAutocomplete from '../helpers/InputAutocomplete/InputAutocomplete';
import ProductsListHeader from './ProductsListHeader';
import ProductsListItem from './ProductsListItem';
import ProductModal from '../products/ProductModal';
import AdminProductModal from './admin/AdminProductModal'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { fetchUser } from './../../redux/userSlice';
import { clearUser } from './../../redux/userSlice';
import { useDebounce } from './../../utils';
import './catalog.css';
function Catalog({
  lastUpdate = { lastUpdate } - 1,
  isAdmin = false,
  onSelect = () => { },
  onCatalogChange = () => { },
  defaultCatalog = null,
}) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({ prodMarque: '', prodModele: '', prodRef: '', prodMemo: '', prodAnnee: '', isDirty: false, featured: true });
  const user = useSelector((state) => state.user);
  const demoMode = ['user56519edd43c76', 'user6384c97c69c84', 'user5d765490a3a36'].includes(user.id);
  const yearsFilterEnabled = demoMode;
  const dispatch = useDispatch();
  const debouncedInputValue = useDebounce(filters, 300);
  const [isLoading, setIsLoading] = useState(false);
  const suggestMarques = useRef(null);
  const suggestModeles = useRef(null);
  const suggestRef = useRef(null);
  const inputAnnee = useRef(null);
  const inputDescription = useRef(null);
  const [allData, setAllData] = useState([]); // données chargées par l'api (extrait du catalogue)
  const [filteredData, setFilteredData] = useState([]);
  const [constructors, setConstructors] = useState([]);
  const [models, setModels] = useState([]);
  const [references, setReferences] = useState([]);
  const [kits, setKits] = useState([]);
  const [availablesCatalogs, setAvailableCatalogs] = useState([])
  const [selectedCatalog, setSelectedCatalog] = useState(defaultCatalog);
  useEffect(() => {
    if (isAdmin && !user.isAdmin) {
      window.location.href = "/"
      dispatch(clearUser());
    }

    if (availablesCatalogs.length == 0) {
      if (user.catalogs) {
        setAvailableCatalogs(user.catalogs);
        let catalog = window.localStorage.getItem('catalog');
        if (!catalog || user.catalogs.indexOf(catalog) == -1) {
          catalog = user.catalogs[0];
        }
        setSelectedCatalog(catalog)
      }
    }
  }, [user])
  useEffect(() => {

    dispatch(fetchUser());
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setFilters({ prodMarque: '', prodModele: '', prodRef: '', prodMemo: '', prodAnnee: '', isDirty: false, featured: true });
        switch (selectedCatalog?.toLowerCase()) {
          case 'kit':
            inputDescription.current.focus();
            break;
          default:
            const uniqueModels = Array.from(new Set(allData.map(item => item.prodModele.trim())))
              .map(name => ({ name }));
            setModels(uniqueModels);
            suggestMarques.current.focus();
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    if (defaultCatalog) {
      // setSelectedCatalog(defaultCatalog)
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  useEffect(() => {
    if (lastUpdate > -1) {
      fetchProducts();
    }
  }, [lastUpdate])
  /**********
   * Load products on catalog change
   * when inputs in InputAutocomplete change
   */
  useEffect(() => {
    if (selectedCatalog) {
      setIsLoading(true);
      window.localStorage.setItem('catalog', selectedCatalog);
      fetchProducts();
      onCatalogChange(selectedCatalog)
    }
  }, [selectedCatalog]);
  const fetchProducts = () => {
    const path = isAdmin ? '/admin/products' : '/products';
    if (!selectedCatalog) {
      return;
    }
    API.get(`${path}?query={"prodcatalogue:eq":"${selectedCatalog}"}`).then(response => {
      const uniqueReferences = Array.from(new Set(response.data.map(item => item.prodRef.trim())))
        .map(name => ({ name }));
      setReferences(uniqueReferences);
      switch (selectedCatalog?.toLowerCase()) { //
        case 'kit':
          console.log(response.data)
          setKits([...new Set(response.data.map(item => {
            let str = item.prodMemo != '' ? item.prodMemo : item.prodTitre
            return str;
          }))]);
          setFilteredData(response.data);
          break;
        default:
          const uniqueConstructors = Array.from(new Set(response.data.map(item => item.prodMarque.trim())))
            .map(name => ({ name }));
          setConstructors(uniqueConstructors);
          const uniqueModels = Array.from(new Set(response.data.map(item => item.prodModele.trim())))
            .map(name => ({ name }));
          setModels(uniqueModels);
          setFilteredData([]);
          window.setTimeout(() => {
            //  ApplyFilters();
          }, 700);
      };
      setAllData(response.data);
      window.setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
  }
  /**********
  * handleOnChange()
  * when inputs in InputAutocomplete change
  */
  const handleOnChange = (e) => {
    let value = e.target.value;
    const filters_copy = { ...filters };
    if (e.target.name == 'prodMarque') {
      filters_copy.prodModele = '';
      filterModels(e.target.value, filters_copy.prodAnnee);  // pour le select Modeles
      /*
      if (e.target.value == '') {
        const uniqueModels = Array.from(new Set(allData.map(item => item.prodModele.trim())))
          .map(name => ({ name }));
        setModels(uniqueModels);
        // setModels([...new Set(allData.map(item => item.prodModele.trim()))]);
      } else {
        setModels([]);
      }
        */
    }
    if (e.target.name === 'prodAnnee') {
      value = value.replace(/[^0-9]/, ''); // Retirer les caractères non numériques
      // Si l'année contient 4 chiffres, on filtre les modèles par marque et année
      filterModels(filters_copy.prodMarque, value);  // pour le select Modeles
      /*
      if (value.length === 4) {
        const filteredModels = allData.filter(item =>
          (value >= item.availableYears[0] && value <= item.availableYears[1]) && item.prodMarque === filters_copy.prodMarque
        );
        const uniqueModels = Array.from(new Set(filteredModels.map(item => item.prodModele.trim())))
          .map(name => ({ name }));
        setModels(uniqueModels);
      } else {

      }
      */
    }
    filters_copy[e.target.name] = value;
    filters_copy.isDirty = (filters_copy.prodMarque + filters_copy.prodModele + filters_copy.prodRef + filters_copy.prodMemo + filters_copy.prodAnnee).trim() !== '';
    setFilters(filters_copy);
  }
  const handleSuggestionChange = (e) => {
    const filters_copy = { ...filters };
    const { name, value } = e.target;
    if (name == 'prodMarque') {
      filters_copy.prodMarque = value;
      filters_copy.prodModele = '';
    }
    if (name == 'prodModele') {
      filters_copy.prodModele = value;
    }
    if (name == 'prodRef') {
      filters_copy.prodRef = value;
    }
    filters_copy.isDirty = (filters_copy.prodMarque + filters_copy.prodModele + filters_copy.prodRef + filters_copy.prodMemo + filters_copy.prodAnnee).trim() !== '';
    setFilters(filters_copy);
  }
  /**********
  * 
  * 
  */
  const handleSuggestionSelect = (name, value) => {
    if (name == 'prodMarque') {
      const filteredData = allData.filter(item => item.prodMarque === value.name);
      const uniqueModels = Array.from(new Set(filteredData.map(item => item.prodModele.trim())))
        .map(name => ({ name }));
      setModels(uniqueModels);
      setFilters({ ...filters, prodMarque: value.name, prodModele: '' })
      if (yearsFilterEnabled) {
        inputAnnee.current.focus();
      } else {
        suggestModeles.current.focus();
      }
    }
    if (name == 'prodModele') {
      setFilters({ ...filters, prodModele: value.name })
    }
    if (name == 'prodRef') {
      setFilters({ ...filters, prodRef: value.name })
    }
  }
  /**********
  *  call back de debouncing
  *  
  */
  const ApplyFilters = () => {
    let filteredData = [];
    switch (selectedCatalog?.toLowerCase()) {
      case 'kit':
        filteredData = allData.filter(item => {
          return (
            (
              item.prodTitre.toLowerCase().indexOf(filters.prodMemo.toLowerCase()) > -1 ||
              item.prodMemo.toLowerCase().indexOf(filters.prodMemo.toLowerCase()) > -1
            ) &&
            item.prodRef.toLowerCase().startsWith(filters.prodRef.toLowerCase())
            /*
            &&
            (filters.prodFeatured === false || item.prodFeatured === '1')
            */
          );
        });
        break;
      default:
        if (filters.prodMarque != '' || filters.prodModele != '' || filters.prodRef.length > 2) {
          filteredData = allData.filter(item => {
            return (
              item.prodMarque.toLowerCase().startsWith(filters.prodMarque.toLowerCase())
              && item.prodModele.toLowerCase().startsWith(filters.prodModele.toLowerCase())
              && item.prodRef.toLowerCase().startsWith(filters.prodRef.toLowerCase())
              && (filters.prodAnnee.length == 4 ? (item.availableYears[0] <= parseInt(filters.prodAnnee) && item.availableYears[1] >= parseInt(filters.prodAnnee)) : true)
            );
          });
        }
    }
    setFilteredData(filteredData);
  }
  useEffect(() => {
    if (debouncedInputValue) {
      ApplyFilters();
    }
  }, [debouncedInputValue, allData]);
  const handleCloseModal = (product) => {
    // setShowProductModal(false);
  }

  /* filtre les models pour le select modeles */
  const filterModels = (prodMarque, prodAnnee) => {

    window.setTimeout(() => {
      // Filtrer les modèles en fonction de la marque et de l'année
      let filteredModels = allData;
      // const prodMarque = filters.prodMarque;
      // const prodAnnee = filters.prodAnnee;
      if (prodMarque) {
        filteredModels = filteredModels.filter(item => item.prodMarque === prodMarque);
      }

      if (prodAnnee && prodAnnee.length > 0) {
        // console.log('passe la');
        filteredModels = filteredModels.filter(item => (prodAnnee >= item.availableYears[0] && prodAnnee <= item.availableYears[1]));
      }




      setModels(
        Array.from(new Set(filteredModels.map(item => item.prodModele.trim())))
          .map(name => ({ name }))
      );
    })
  };


  return (
    <div id='catalog'>
      <div className='search py-3 px-5 mb-2 bg-green-dark'>
        <div className='mb-1 catalog-select'>
          <label className='form-group-label me-2'>
            Catalogue :
          </label>
          {availablesCatalogs.map((catalog, index) => (
            <label key={index} className='radio-label me-3'>
              <input
                type="radio"
                name="prodCatalogue"
                value={catalog}
                checked={catalog === selectedCatalog} // Ajoute la prop checked
                onChange={() => setSelectedCatalog(catalog)} // Utilise onChange à la place de onClick pour gérer le changement
                className='me-1'
              />
              {t(`plural-${catalog.toLowerCase()}`)}
            </label>
          ))}
        </div>

        {/* filteredData.length       */}
        {selectedCatalog?.toLowerCase() != 'kit' &&
          <div className='row mb-1'>
            <div className='col-sm-4'>
              <div className=''>
                <label className='form-group-label'>{t('Constructor')} :</label>
                <InputAutocomplete
                  inputRef={suggestMarques}
                  disabled={false}
                  readonly={false}
                  suggestionsSrc={constructors}
                  name='prodMarque'
                  placeholder=''
                  value={filters.prodMarque}
                  displayKey="name"
                  onChange={handleSuggestionChange}
                  handleOnSuggestsChange={e => { }}
                  formatSuggestion={(key, constructor) => constructor.name}
                  onSuggestionSelect={handleSuggestionSelect}
                  suggestionsOnFocus={true}
                  debug={false} />

              </div>
            </div>
            {yearsFilterEnabled &&
              <div className='col-sm-4'>
                <div className=''>
                  <label className='form-group-label'>{t('Year')} : </label>
                  <input type="text" className='form-control'
                    ref={inputAnnee}
                    name="prodAnnee"
                    value={filters.prodAnnee}
                    onChange={handleOnChange}
                    min={2000}
                    maxLength={4}
                  />
                </div>
              </div>
            }
            <div className='col-sm-4'>
              <div className=''>
                <label className='form-group-label'>{t('Model')} :</label>
                <InputAutocomplete
                  inputRef={suggestModeles}
                  disabled={false}
                  readonly={false}
                  suggestionsSrc={models}
                  name='prodModele'
                  placeholder=''
                  value={filters.prodModele}
                  displayKey="name"
                  onChange={handleSuggestionChange}
                  handleOnSuggestsChange={e => { }}
                  formatSuggestion={(key, modele) => modele.name}
                  onSuggestionSelect={handleSuggestionSelect}
                  suggestionsOnFocus={filters.prodMarque != ''}
                  debug={false} />
              </div>
            </div>
            {/*

            */}
          </div>
        }
        <div className='row'>
          {/*  */}
          <div className='col-sm-4'>
            <label className='form-group-label'>{t('Reference')} :
              {filters.prodMemo.length > 0 && filters.prodMemo.length < 3 && <em className='small'> 3 caractères minimum</em>}
            </label>
            <InputAutocomplete
              inputRef={suggestRef}
              disabled={false}
              readonly={false}
              suggestionsSrc={references}
              name='prodRef'
              placeholder=''
              value={filters.prodRef}
              displayKey="name"
              onChange={handleSuggestionChange}
              handleOnSuggestsChange={e => { }}
              formatSuggestion={(key, reference) => reference.name}
              onSuggestionSelect={handleSuggestionSelect}
              debug={false} />
          </div>
          {selectedCatalog?.toLowerCase() === 'kit' &&
            <div className='col-sm-8'>
              <label className='form-group-label'>Description :
                {/* filters.prodMemo.length > 0 && filters.prodMemo.length < 3 && <em className='small'> 3 caractères minimum</em> */}
              </label>
              <input type="text" className='form-control'
                ref={inputDescription}
                name="prodMemo"
                value={filters.prodMemo}
                onChange={handleOnChange}
              />
            </div>
          }
        </div>
        <p className='small pt-1 mb-0'>Appuyer sur la touche <em>Echap</em> pour supprimer tous les filtres</p>
      </div>
      {isLoading ?
        <div className='loading'>
          <div className='text-center '>
            <ProgressBar variant="gray" animated now={100} />
          </div>
        </div> :
        filteredData.length > 0 ?
          <div className='products-wrapper'>
            <table className='table table-sm table-striped'>
              <ProductsListHeader catalog={selectedCatalog} isAdmin={isAdmin} featuredProductOnly={filters.featured} toggleFeaturedProductOnly={e => setFilters({ ...filters, featured: !filters.featured })} />
              <tbody>
                {filteredData.map((product, idx) => (
                  <ProductsListItem
                    key={idx}
                    product={product}
                    onSelect={onSelect}
                    isSelected={false}
                    isAdmin={isAdmin} />
                ))}
              </tbody>
            </table>
          </div>
          :
          <div className="text-center fs-5 py-5">
            {filters.isDirty ?
              <>Aucun produit ne correspond à votre recherche.</>
              :
              <>Utilisez les fitres ci-dessus pour effectuer une recherche.</>}
          </div>
      }
      {/* 
        showProductModal &&
        (isAdmin ?
          <AdminProductModal product={selectedProduct} onClose={handleCloseModal} />
          :
          <ProductModal product={selectedProduct} onClose={handleCloseModal} />
        )
      */ }
      {debug(filters)}
    </div >
  );
}
export default Catalog;
