
import React, { useState, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import API from '../../api'

import ProductsListHeader from './ProductsListHeader';
import ProductsListItem from './ProductsListItem';
import { debug } from './../../utils'
import { FaArrowRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { fetchUser } from './../../redux/userSlice';
import { BiSearch } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { VscLoading } from "react-icons/vsc";
import './product-search-bar.css';
const ProductSearchBar = ({ onSelect }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [results, setResults] = useState([])
  const [availablesCatalogs, setAvailableCatalogs] = useState([])
  const [catalog, setCatalog] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [displayResults, setDisplayResults] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedIdx, setSelectedIdx] = useState('');
  const resultWrapper = useRef(null);
  useEffect(() => {
    dispatch(fetchUser());
  }, [])

  useEffect(() => {
    if (availablesCatalogs.length == 0) {
      if (user.catalogs) {

        setAvailableCatalogs(user.catalogs);
        setCatalog(user.catalogs[0])
      }
    }
  }, [user])
  const fetchSearch = async () => {
    console.log('fetchSearch')
    if (query.length >= 3) {
      setIsLoading(true)
      async function getProducts() {
        const response = await API.get('/products/search?q=' + query + '&c=' + catalog);
        setResults(response.data);
        setDisplayResults(true);
        setSelectedIdx(0);
        setIsLoading(false);
        // onSelect(response.data[0])
      }
      getProducts();
    } else {
      setResults([]);
    }
  }
  useEffect(() => {
    console.log('useEffect query')
    fetchSearch()
  }, [query, catalog])
  useEffect(() => {
    if (results.length > 0 && displayResults) {
      setSelectedIdx(selectedIdx);
      const rows = document.querySelectorAll('.results tr');
      const selectedRow = rows[selectedIdx];
      const selectedRowRect = selectedRow.getBoundingClientRect(); // Obtient les coordonnées de l'élément sélectionné par rapport à la fenêtre
      const resultsTableRect = document.querySelector('.results table').getBoundingClientRect(); // Obtient les coordonnées de la div "results" par rapport à la fenêtre
      resultWrapper.current.scrollTop = selectedRowRect.top - resultsTableRect.top;
    }
  }, [selectedIdx])
  const handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      onSelect(results[selectedIdx])
    }
    if (e.keyCode == 38 || e.keyCode == 40) {
      const step = e.keyCode === 40 ? -1 : 1;
      const newSelectedIdx = Math.min(Math.max(0, selectedIdx - step), results.length - 1);
      setSelectedIdx(newSelectedIdx);
    }
  }
  const handleFocus = () => {
    console.log('ICI', query.length, results.length, isLoading)
    if (results.length > 0) {
      setDisplayResults(true);
      // fetchSearch();
    }
  }
  useEffect(() => { console.log('displayResults', displayResults) }, [displayResults])
  return (
    <div className='container '>
      <form className='product-search-bar border border-green rounded p-3 '>
        <h2 className="section-title text-green mb-3">Commander ou rechercher une fiche technique</h2>
        <div className='form-group'>
          <div className='position-relative'>
            <div className='input-group'>
              <span className='input-group-text'>
                {isLoading ? <VscLoading className="loading-icon" /> : <BiSearch />}
              </span>
              <span className='input-group-text'>
                <select className='' onChange={(e) => setCatalog(e.target.value)}>
                  {availablesCatalogs.map((cat, idx) => (
                    <option key={idx} value={cat} checked={catalog == cat ? true : false} >
                      {t(`plural-${cat.toLowerCase()}`)}
                    </option>
                  ))}
                </select>
              </span>
              <input
                type="text"
                className='form-control form-control-lg'
                placeholder='Rechercher par constructeur, modele ou référence'
                value={query}
                onChange={e => setQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
              />
              {(results.length > 0 && displayResults == true) &&
                <span className='input-group-text' onClick={e => setDisplayResults(false)}>
                  <AiOutlineCloseCircle className="pointer text-green" title="Effacer les résultats" size={24} />
                </span>
              }
            </div>
            {(results.length > 0 && displayResults === true) &&
              <div className='results' ref={resultWrapper}>
                <table className='table table-bordered table-striped'>
                  <ProductsListHeader catalog={catalog} />
                  <tbody>
                    {results.map((result, idx) => (
                      <ProductsListItem key={idx} product={result} onSelect={e => { onSelect(result) }} isSelected={idx == selectedIdx} />
                    ))}
                  </tbody>
                </table>
              </div>
            }
          </div>
          <div className="d-flex justify-content-between pt-2">
            <div className='small'>Saisir 3 caractères minimum</div>
            <div className='small'>
              <FaArrowRight className="text-green me-2" />
              <Link to="/catalog" className="text-green decoration-on-hover" title="Rechercher par contructeurs, modèles, etc">
                Recherche avancée
              </Link>
            </div>
          </div>
        </div>
      </form >
    </div >
  )
};
export default ProductSearchBar;
