import React, { useEffect, useState, useRef } from 'react';
import './input-autocomplete.css';
import { debug } from './../../../utils'
const InputAutocomplete = ({
  suggestionsSrc,
  value,
  onSuggestionSelect,
  onChange,
  name,
  filterMode = 'startsWith',
  displayKey,
  formatSuggestion,
  inputRef = null,
  suggestionsOnFocus = false,
  disabled = false,
  placeholder = '',
  className = '',
  inputClassName = '',
  handleOnSuggestsChange = null,
  debug: debug_on = false
}) => {
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const suggestionsContainerRef = useRef(null);
  const [filteredSuggestionsSrc, setFilteredSuggestionsSrc] = useState(suggestionsOnFocus ? suggestionsSrc : []);
  const normalizeValue = (value) =>
    value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[\s-]/g, ''); // Supprimer espaces et tirets
  const filterSuggestions = (value) => {
    const normalizedValue = normalizeValue(value);
    // console.log(value, normalizedValue)
    let filteredSuggestions = [];
    if (filterMode === 'startsWith') {
      filteredSuggestions = suggestionsSrc.filter(
        (item) => {
          // console.log(item[displayKey], normalizeValue(item[displayKey]).startsWith(normalizedValue));
          return normalizeValue(item[displayKey]).startsWith(normalizedValue)
        }
      );
    } else if (filterMode === 'includes') {
      filteredSuggestions = suggestionsSrc.filter(
        (item) =>
          normalizeValue(item[displayKey]).includes(normalizedValue)
      );
    }
    // console.log(filteredSuggestions)
    setFilteredSuggestionsSrc(filteredSuggestions);
    setSelectedSuggestionIndex(-1);
    if (handleOnSuggestsChange) {
      handleOnSuggestsChange(filteredSuggestions.length);
    }
  };
  const handleInputChange = (event) => {
    const { value } = event.target;
    setDisplaySuggestions(value !== '' || suggestionsOnFocus);
    onChange(event);
    filterSuggestions(value);
  };
  const handleSuggestionClick = (suggestion) => {
    if (suggestion && suggestion[displayKey]) {
      onSuggestionSelect(name, suggestion);
    }
    setDisplaySuggestions(false);
  };
  const scrollToSelectedSuggestion = (index) => {
    if (index >= 0 && suggestionsContainerRef.current) {
      const suggestionElement = suggestionsContainerRef.current.children[index];
      if (suggestionElement) {
        suggestionsContainerRef.current.scrollTop = suggestionElement.offsetTop;
      }
    }
  };
  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        if (displaySuggestions) {
          setSelectedSuggestionIndex((prevIndex) =>
            prevIndex <= 0 ? filteredSuggestionsSrc.length - 1 : prevIndex - 1
          );
        } else {
          setDisplaySuggestions(true);
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (displaySuggestions) {
          setSelectedSuggestionIndex((prevIndex) =>
            prevIndex >= filteredSuggestionsSrc.length - 1 ? 0 : prevIndex + 1
          );
        } else {
          setDisplaySuggestions(true);
        }
        break;
      case 'Enter':
        event.preventDefault();
        if (filteredSuggestionsSrc.length > 0 && selectedSuggestionIndex >= 0) {
          handleSuggestionClick(filteredSuggestionsSrc[selectedSuggestionIndex]);
        }
        setDisplaySuggestions(false);
        break;
      case 'Escape':
        setDisplaySuggestions(false);
        break;
      default:
        break;
    }
  };
  const handleOnFocus = () => {
    setDisplaySuggestions(suggestionsOnFocus);
  };
  const handleOnBlur = () => {
    setTimeout(() => {
      setDisplaySuggestions(false);
    }, 200);
  };
  useEffect(() => {

    filterSuggestions('');
  }, [suggestionsOnFocus, suggestionsSrc]) // a l'initialisation
  useEffect(() => {
    scrollToSelectedSuggestion(selectedSuggestionIndex);
  }, [selectedSuggestionIndex]);
  return (
    <div className={`autocomplete-input ${className}`}>
      <input
        ref={inputRef}
        type="text"
        name={name}
        placeholder={placeholder}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        disabled={disabled}
        value={value || ''}
        autoComplete="off"
        className={`form-control ${inputClassName}`}
      />
      {displaySuggestions && (
        <div className="autocomplete-container" ref={suggestionsContainerRef}>
          {filteredSuggestionsSrc.map((item, index) => (
            <div
              key={index}
              className={`suggestion ${index === selectedSuggestionIndex ? 'selected' : ''}`}
              onClick={() => handleSuggestionClick(item)}
            >
              {formatSuggestion(name, item)}
            </div>
          ))}
        </div>

      )}

    </div>
  );
};
export default InputAutocomplete;
